import {
  postDecommissionHub,
  putHubInactiveStatus,
} from "@intelligentlilli/api-layer";
import { useCallback, useState } from "react";
import { useAppSelector } from "State/hooks";

export const useDeactivateServiceUser = ({
  serviceUserId,
  reason,
  outcome,
  reasonText,
}: {
  serviceUserId: string;
  reason: string;
  outcome: string;
  reasonText: string;
}) => {
  // redux state
  const server = useAppSelector((state) => state.session.server);

  // local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const deactivateServiceUser = useCallback(async () => {
    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const decommissionResponse = await postDecommissionHub({
        server,
        id: serviceUserId,
        origin: "web",
        reason,
        outcome,
        reasonOther: reasonText,
      });
      if (decommissionResponse.ok) {
        const inactiveResponse = await putHubInactiveStatus(
          server,
          serviceUserId,
          "web"
        );

        if (inactiveResponse.ok) {
          setSuccess(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [outcome, reason, reasonText, server, serviceUserId]);

  return { loading, error, deactivateServiceUser, success };
};
