import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const serviceUsersSlice = createSlice({
  name: "serviceUsers",
  initialState,
  reducers: {
    setServiceUsers(_, action) {
      return action.payload;
    },
    updateServiceUser(state, action) {
      const { index, key, value } = action.payload;
      state[index][key] = value;
    },
    updateServiceUserProperties(state, action) {
      const { index, key, value } = action.payload;
      state[index].serviceUserProperties[key] = value;
    },
    //
    removeServiceUser(state, action) {
      const index = state.findIndex(
        (SU) => String(SU.id) === String(action.payload)
      );
      if (index !== -1) {
        state.splice(index, 1); // Directly removes the item at the found index
      }
    },
  },
});

export const {
  setServiceUsers,
  updateServiceUser,
  updateServiceUserProperties,
  removeServiceUser,
} = serviceUsersSlice.actions;
export default serviceUsersSlice.reducer;
