import React, { useState } from "react";
import NewStyleModal from "../NewStyleModal";
import LilliButton from "../DesignSystemComponents/LilliButton/LilliButton";
import styles from "./styles.module.scss";
import { InfoIcon } from "../../Styles/Icons/DesignSystem";
import { useNavigate } from "react-router-dom";
import theme from "../../Styles/theme.scss";
import RemoveUserForm from "./RemoveServiceUserForm/index";
import { useDeactivateServiceUser } from "../../Services/Hooks/useDeactivateServiceUser";
import { useGetOffboardingReasons } from "../../Services/Hooks/useGetOffboardingReasons";
import {
  setRefreshOrganisationHubs,
  setRefreshUser,
} from "../../State/slices/session-slice";
import { removeServiceUser } from "State/slices/serviceUsers-slice";
import { removeServiceUserData } from "State/slices/serviceUsersData-slice";
import { useAppDispatch } from "State/hooks";

interface RemoveServiceUserModalProps {
  hubId: string;
  serviceUser: any;
  hide: () => void;
  showAnimation: boolean;
  setShowModal: (val: string) => void;
  isManagerOrAdmin: boolean;
}

const RemoveServiceUserModal = ({
  hubId,
  serviceUser,
  hide,
  showAnimation,
  setShowModal,
  isManagerOrAdmin,
}: RemoveServiceUserModalProps) => {
  const [modalStep, setModalStep] = useState(1);
  const [reason, setReason] = useState(); // array[{value: string, label: string}] | undefined
  const [reasonText, setReasonText] = useState(""); // string
  const [outcome, setOutcome] = useState(); // array[{value: string, label: string}] | undefined
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const title = (serviceUser) => ({
    1: "Are you sure you want to remove this user from Lilli?",
    2: "Before removing this user...",
    3: error
      ? `An error occurred. ${serviceUser?.userstring ?? "This user"} was not removed. Please try again`
      : `You're about to remove ${serviceUser?.userstring ?? "this user"} from the platform.`,
  });

  const { data: offboardingReasonsData } = useGetOffboardingReasons({
    serviceUserId: hubId,
  });

  const { deactivateServiceUser, loading, error, success } =
    useDeactivateServiceUser({
      serviceUserId: hubId,
      reason: reason?.value,
      outcome: outcome?.value,
      reasonText:
        reason?.value === "other" && reasonText !== "" ? reasonText : undefined,
    });

  const handleDeactivate = async () => {
    await deactivateServiceUser();
  };

  const finishOffboardingFlow = () => {
    dispatch(removeServiceUser(hubId));
    dispatch(removeServiceUserData({ hubId }));

    if (isManagerOrAdmin) {
      dispatch(setRefreshOrganisationHubs(true));
    }

    dispatch(setRefreshUser(true));

    navigate("/");
  };

  if (success) {
    return (
      <NewStyleModal
        showCloseIcon={true}
        hide={finishOffboardingFlow}
        useFade={true}
        showAnimation={showAnimation}
        size="small"
      >
        <h4 className={styles.header}>
          {serviceUser.userstring} has been removed from the platform.
        </h4>
        <p className={styles.text}>Click below to continue.</p>
        <div className={styles.buttons}>
          <div />
          <LilliButton variant="primary" onClick={finishOffboardingFlow}>
            Go to dashboard
          </LilliButton>
        </div>
      </NewStyleModal>
    );
  }

  return (
    <NewStyleModal
      showCloseIcon={true}
      hide={() => hide()}
      useFade={true}
      showAnimation={showAnimation}
      size="small"
    >
      <>
        <h4 className={styles.header}>{title(serviceUser)[modalStep]}</h4>
        {/* STEP 1 */}

        {modalStep === 1 ? (
          <div className={styles.step_one_content}>
            <div className={styles.banner}>
              <InfoIcon colour={theme.neutral7} />
              <div>
                <span className={styles.bold}>Remember:</span> You can keep a
                version of all this user's data by{" "}
                <span
                  className={styles.reports_link}
                  onClick={() => navigate("/reports")}
                >
                  creating a report here
                </span>
              </div>
            </div>
            <p className={styles.text}>
              Removing this user will stop collecting data for this user.
            </p>
          </div>
        ) : null}
        {/* STEP 2 */}
        {modalStep === 2 ? (
          <RemoveUserForm
            serviceUser={serviceUser}
            reason={reason}
            setReason={setReason}
            outcome={outcome}
            setOutcome={setOutcome}
            reasonText={reasonText}
            setReasonText={setReasonText}
            offboardingReasonsData={offboardingReasonsData}
          />
        ) : null}
        {/* STEP 3 && API ERROR */}
        {modalStep === 3 ? (
          <div className={styles.step_one_content}>
            {error ? (
              <p className={styles.error_text}>
                We're sorry, but we couldn't remove the user from the platform
                at this time. Please try again.
              </p>
            ) : null}
            <p className={styles.text}>
              Once the user is removed they will no longer be visible by you, or
              your team.
            </p>
            <p className={styles.text}>
              This Lilli kit can be re-used in another home immediately. If you
              need information on collecting the kit from a users home and
              re-installing it please contact the Lilli support team on{" "}
              <a
                href="mailto: support@intelligentlilli.com"
                target="_blank"
                rel="noreferrer"
                className={styles.email}
              >
                support@intelligentlilli.com
              </a>
            </p>
            <p className={styles.text}>Do you still want to proceed?</p>
          </div>
        ) : null}

        {modalStep === 1 || modalStep === 2 ? (
          <div className={styles.buttons}>
            <LilliButton
              variant="warning"
              onClick={
                modalStep === 1
                  ? () => setShowModal("EditProfile")
                  : () => setModalStep(1)
              }
            >
              No, go back
            </LilliButton>
            <LilliButton
              variant="primary"
              onClick={() => setModalStep(modalStep === 1 ? 2 : 3)}
              disabled={
                modalStep === 2 &&
                (!reason ||
                  !outcome ||
                  (reason?.value === "other" && reasonText === ""))
              }
            >
              Yes, continue
            </LilliButton>
          </div>
        ) : null}
        {modalStep === 3 ? (
          <div className={styles.buttons}>
            <LilliButton variant="warning" onClick={() => setModalStep(2)}>
              No, go back
            </LilliButton>
            <LilliButton
              variant="danger"
              loading={loading}
              onClick={handleDeactivate}
            >
              Yes, continue
            </LilliButton>
          </div>
        ) : null}
      </>
    </NewStyleModal>
  );
};

export default RemoveServiceUserModal;
